// Get existing cookies
var cookies = document.cookie.split(";");
cookies.forEach((el, i) => {
    var cookie = el.split("=");
    cookies[cookie[0].trim()] = cookie[1];
});

// Set html attribute and update button classes
if (cookies["fontSize"] == "lg" || cookies["fontSize"] == "xl") {
    document.documentElement.setAttribute(
        "data-font-size",
        cookies["fontSize"]
    );

    document
        .querySelectorAll(
            "a.rd-button:not([data-font-size='" + cookies["fontSize"] + "'])"
        )
        .forEach((el) => {
            el.classList.remove("active");
        });

    document
        .querySelectorAll(
            "a.rd-button[data-font-size='" + cookies["fontSize"] + "']"
        )
        .forEach((el) => {
            el.classList.add("active");
        });
}
